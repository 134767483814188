/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { CustomTable, Fields, Icons, Paginate } from "components"
import { Form } from "containers"

import { useWatch } from "react-hook-form"
import propTypes from "prop-types"

import "./select-modal.scss"

function SelectModalList({
  data,
  setSelected,
  setOpen,
  columns,
  setPage,
  pageCount,
  onSearch,
  isLoading,
}) {
  return (
    <div className="select-modal table">
      <div className="table__header pt-10">
        <Form onSubmit={onSearch}>
          {({ control, submit }) => (
            <>
              <Fields.Input name="search" placeholder="Search..." onChange={submit} />
              <SearchIcons control={control} />
            </>
          )}
        </Form>
      </div>
      <div className="table__wrapper">
        <CustomTable
          onRow={(item) => ({
            onClick: () => {
              setSelected(item)
              setOpen(false)
            },
          })}
          data={data}
          columns={columns}
          isLoading={isLoading}
        />
        <div className="pr-20">
          <Paginate setPage={setPage} pageCount={pageCount} />
        </div>
      </div>
    </div>
  )
}

export default SelectModalList

SelectModalList.propTypes = {
  data: propTypes.array,
  setSelected: propTypes.func,
  setOpen: propTypes.func,
  columns: propTypes.array,
  setPage: propTypes.func,
  pageCount: propTypes.number,
  onSearch: propTypes.func,
  isLoading: propTypes.bool,
}

SelectModalList.defaultProps = {
  data: [],
  setOpen: () => {},
  setSelected: () => {},
  setPage: () => {},
  onSearch: () => {},
  columns: [],
  pageCount: 0,
  isLoading: false,
}

// eslint-disable-next-line react/prop-types
function SearchIcons({ control }) {
  const search = useWatch({
    control,
    name: "search",
  })
  return search ? <Icons.XIcon className="xicon" /> : <Icons.Search className="search" />
}
