/* eslint-disable no-prototype-builtins */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { helpers } from "services"
import propTypes from "prop-types"
import { useMutate } from "hooks"
import { toFormData } from "axios"
import { keys } from "lodash"

export default function Form({
  children,
  fields,
  url,
  method = "post",
  urlSearchParams,
  onSuccess = () => {},
  onError = () => {},
  queryKey,
  initialValues,
  className,
  isFormdata,
  onSubmit,
  onValidationFail,
  onValidate = () => {},
  route,
}) {
  const methods = useForm({
    resolver: yupResolver(helpers.validateForm({ fields })),
    defaultValues: initialValues,
  })
  const mutation = useMutate({
    url,
    urlSearchParams,
    queryKey,
    method,
    onSuccess: (data) => onSuccess(data, methods),
    onError: (err) => onError(err, methods),
  })

  const handleSubmit = methods.handleSubmit((obj) => {
    const requestData = { ...obj }
    onSubmit(obj, methods)
    fields.map((field) => {
      const { name, submitKey } = field
      if (field.hasOwnProperty("submitValue")) {
        const value = field.submitValue(obj[field.name], obj, methods)
        if (value !== undefined && value !== null) requestData[submitKey || name] = value
        else delete requestData[name]
      } else requestData[submitKey || name] = obj[name]
      return null
    })
    const objKeys = keys(requestData)
    objKeys.forEach((key) => {
      if (requestData[key] === null || requestData[key] === undefined) delete requestData[key]
    })
    onValidate(requestData, obj)
    if (url)
      mutation.mutate({
        data: isFormdata ? toFormData(requestData) : requestData,
        isFormdata,
        route,
      })
  }, onValidationFail)

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={handleSubmit}>
        {children({ submit: handleSubmit, resetForm: methods.reset, ...methods, ...mutation })}
      </form>
    </FormProvider>
  )
}

Form.propTypes = {
  children: propTypes.func.isRequired,
  fields: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      type: propTypes.string,
      required: propTypes.bool,
      min: propTypes.number,
      max: propTypes.number,
    }),
  ),
  initialValues: propTypes.object,
  isFormdata: propTypes.bool,
  onSubmit: propTypes.func,
  ...useMutate.propTypes,
}

Form.defaultProps = {
  fields: [],
  initialValues: {},
  isFormdata: false,
  onSubmit: () => {},
  ...useMutate.defaultProps,
}
