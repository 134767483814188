import ReactDOM from "react-dom"
import propTypes from "prop-types"

import "./modal.scss"
import { Icons } from "components"

function Modal({ children, isOpen, hasOutsideClick, handleClose, title, className, maxWidth }) {
  return ReactDOM.createPortal(
    <div className={`modal ${isOpen ? "open" : "close"} ${className}`}>
      <div className="modal__overlay" onClick={() => hasOutsideClick && handleClose()} />
      <div className="modal__content" onClick={(e) => e.stopPropagation()} style={{ maxWidth }}>
        <div className="modal__box">
          <div className="modal__header">
            <p className="modal__title">{title}</p>
            <div style={{ padding: "10px" }} onClick={handleClose}>
              <Icons.XIcon className="select-modal__close-icon" style={{ cursor: "pointer" }} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("modal"),
  )
}

export default Modal

Modal.propTypes = {
  children: propTypes.oneOfType([propTypes.element, propTypes.string, propTypes.array]),
  isOpen: propTypes.bool,
  hasOutsideClick: propTypes.bool,
  title: propTypes.string,
}

Modal.defaultProps = {
  children: "",
  isOpen: false,
  hasOutsideClick: true,
  title: "",
}
