/* eslint-disable react/forbid-prop-types */

import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"

import "./radios.scss"
import { get } from "lodash"

function Radios({ name, label, required, options, onClick }) {
  const { register, watch } = useFormContext()
  const inputValue = watch(name)
  register(name)

  return (
    <div>
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <ul className="form-radios">
        {options.map((data) => (
          <li key={get(data, "value")} onClick={() => onClick(data)}>
            <div className={`form__radio ${get(data, "value") === inputValue ? "active" : ""}`}>
              {" "}
            </div>
            <input {...register(name)} type="radio" value={get(data, "value")} />
            <p>{get(data, "label")}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Radios

Radios.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.any,
      label: propTypes.string,
    }),
  ),
  onClick: propTypes.func,
}

Radios.defaultProps = {
  label: "",
  required: false,
  options: [],
  onClick: () => {},
}
