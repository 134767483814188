import { toast } from "react-hot-toast"

import "./styles.scss"

const success = (message, options) => {
  toast.success(() => <div onClick={() => toast.dismiss()}>{message || "Successfull"}</div>, {
    closeOnClick: true,
    icon: (
      <div className="toast-success" onClick={() => toast.dismiss()}>
        {" "}
      </div>
    ),
    ...options,
  })
}

const error = (message, options) => {
  toast.error(
    () => <div onClick={() => toast.dismiss()}>{message || "Something went wrong!"}</div>,
    {
      closeOnClick: true,
      icon: (
        <div className="toast-error" onClick={() => toast.dismiss()}>
          {" "}
        </div>
      ),
      ...options,
    },
  )
}

const notification = {
  success,
  error,
}

export default notification
