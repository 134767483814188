import { helpers } from "services"
import { lazy } from "react"

import Fields from "./Fields"
import { Icons } from "./Icons"
import Skeleton from "./Skeleton"
import ModalContents from "./ModalContents"

const Modal = helpers.withSuspense(lazy(() => import("./Modal")))
const Tabs = helpers.withSuspense(lazy(() => import("./Tabs")))
const Delete = helpers.withSuspense(lazy(() => import("./Delete")))
const Filter = helpers.withSuspense(lazy(() => import("./Filter")))
const Paginate = helpers.withSuspense(lazy(() => import("./Paginate")))
const BoolenCol = helpers.withSuspense(lazy(() => import("./BoolenCol")))
const PageHeader = helpers.withSuspense(lazy(() => import("./PageHeader")))
const CustomTable = helpers.withSuspense(lazy(() => import("./CustomTable")))
const PageNavigation = helpers.withSuspense(lazy(() => import("./PageNavigation")))
const SelectModalList = helpers.withSuspense(lazy(() => import("./SelectModalList")))
const ColumnTitleSort = helpers.withSuspense(lazy(() => import("./ColumnTitleSort")))
const DropdownMenu = helpers.withSuspense(lazy(() => import("./DropdownMenu")))
const ProductCard = helpers.withSuspense(lazy(() => import("./ProductCard")))
const List = helpers.withSuspense(lazy(() => import("./List")))
const GoBackHeader = helpers.withSuspense(lazy(() => import("./GoBackHeader")))
const Categories = helpers.withSuspense(lazy(() => import("./Categories")))
const CustomPieChart = helpers.withSuspense(lazy(() => import("./CustomPieChart")))
const Spinner = helpers.withSuspense(lazy(() => import("./Spinner")))

export {
  Tabs,
  Icons,
  Modal,
  Fields,
  Delete,
  Filter,
  Paginate,
  BoolenCol,
  PageHeader,
  CustomTable,
  PageNavigation,
  SelectModalList,
  ColumnTitleSort,
  DropdownMenu,
  ProductCard,
  List,
  GoBackHeader,
  Categories,
  CustomPieChart,
  ModalContents,
  Skeleton,
  Spinner,
}
