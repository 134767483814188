import { Button } from "@mantine/core"

import "../../ProductCard/styles.scss"

function ProductCard() {
  return (
    <div className="card">
      <a
        href="https://100k.uz/shop/product/ultra-apple-smart-watch"
        alt=""
        target="_blank"
        rel="noreferrer"
        className="card__img"
      >
        <img className="sk" alt="" />
      </a>
      <ul>
        <li>
          <h2 className="c-red sk"> </h2>
          <p className="price sk" style={{ marginTop: "10px" }}>
            {" "}
          </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <a aria-current="page" className="c-red active sk" href="/market">
            {" "}
          </a>
        </li>
        <li>
          <Button>Oqim yaratish</Button>
          <a href="/product/1">Batafsil</a>
        </li>
      </ul>
    </div>
  )
}

export default ProductCard
