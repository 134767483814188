import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import "./input.scss"
import cn from "classnames"
import { get } from "lodash"

function Input({ type, name, label, required, className, onChange, ...props }) {
  const { register, watch, formState } = useFormContext()
  watch(name)
  const { errors } = formState
  // const inputType = type === "number" ? "text" : type

  // const handleChange = (e) => {
  //   onChange(e.target.value)
  //   if (type === "number") {
  //     setValue(name, /^\d*\.?\d*$/.test(e.target.value) ? e.target.value : value)
  //   } else {
  //     setValue(name, e.target.value)
  //   }
  // }
  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="input">
        {label}
      </label>

      <input
        {...register(name)}
        type={type}
        className={`form-control ${className}`}
        autoComplete="off"
        maxLength="255"
        name={name}
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Input

Input.propTypes = {
  type: propTypes.string,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]),
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  className: propTypes.string,
  onChange: propTypes.func,
}

Input.defaultProps = {
  type: "text",
  label: "",
  className: "",
  required: false,
  onChange: () => {},
}
