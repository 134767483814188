/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { useState } from "react"
import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import { AsyncPaginate } from "react-select-async-paginate"
import { api } from "services"

import "./async-select.scss"
import { get } from "lodash"
import cn from "classnames"
import { defaultApiRoute } from "config"

function AsyncSelect({
  name,
  label,
  required,
  url,
  optionLabel,
  optionValue,
  params,
  zIndex,
  route = defaultApiRoute,
  axiosOptions,
  filter,
  ...props
}) {
  const [search, setSearch] = useState("")
  const { register, setValue, watch, getValues, formState } = useFormContext()

  watch(name)
  register(name)

  const { errors } = formState
  const loadOptions = async () => {
    if (search.length > 0) params.search = search
    const response = await api({
      url: `${route}/${url}`,
      method: "get",
      params,
      ...axiosOptions,
    })

    const options = get(response, "data.data").map((item) => ({
      ...item,
      value: optionValue(item),
      label: optionLabel(item),
    }))

    return {
      options: filter ? options.filter(filter) : options,
      hasMore: false,
    }
  }

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <AsyncPaginate
        className="select"
        classNamePrefix="select"
        onChange={(option) => setValue(name, option)}
        value={getValues()[name] || null}
        onInputChange={(inputValue) => setSearch(inputValue)}
        loadOptions={loadOptions}
        placeholder=""
        debounceTimeout={300}
        menuPlacement="auto"
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default AsyncSelect

AsyncSelect.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  options: propTypes.array,
  url: propTypes.string,
  optionLabel: propTypes.func,
  optionValue: propTypes.func,
  params: propTypes.object,
  zIndex: propTypes.number,
  defaultValue: propTypes.shape({
    value: propTypes.any,
    label: propTypes.string,
  }),
}

AsyncSelect.defaultProps = {
  label: "",
  required: false,
  options: [],
  defaultValue: {},
  url: "",
  params: {},
  zIndex: 1,
  optionValue: (item) => get(item, "id"),
  optionLabel: (item) => get(item, "title"),
}
