/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */

import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"

import "./checkbox.scss"
import { get } from "lodash"

function Checkbox({
  name,
  label,
  onChange,
  type,
  controlled,
  checkValue,
  onClick,
  disabled,
  className,
}) {
  const { register, setValue, getValues, watch } = useFormContext()
  watch(name)
  register(name)

  const handleChangeTriple = () => {
    onChange(get(getValues(), name))
    const value = get(getValues(), name)
    if (!disabled) {
      if (value === true) {
        setValue(name, false)
      }
      if (value === false) {
        setValue(name, null)
      }
      if (value === null || value === undefined) {
        setValue(name, true)
      }
    }
  }

  const handleChange = () => {
    const newVal = get(getValues(), name) ? null : true
    onChange(newVal)
    if (!disabled) setValue(name, newVal)
  }

  const getClassName = () => {
    if (controlled) {
      return checkValue === false ? "indeterminate" : ""
    }
    return get(getValues(), name) === false ? "indeterminate" : ""
  }
  return (
    <div className={`form-group ${className}`}>
      <div
        className="form-check"
        onClick={type === "triple" ? (controlled ? onClick : handleChangeTriple) : handleChange}
      >
        {type === "triple" ? (
          <input
            type="checkbox"
            className={`form-check-input ${getClassName()}`}
            checked={controlled ? checkValue : get(getValues(), name)}
            onChange={() => {}}
          />
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            checked={!!get(getValues(), name)}
            onChange={() => {}}
            disabled={disabled}
          />
        )}

        <label className="form-check-label" htmlFor="remember">
          {label}
        </label>
      </div>
    </div>
  )
}

export default Checkbox

Checkbox.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]),
  onChange: propTypes.func,
  type: propTypes.string,
  controlled: propTypes.bool,
  checkValue: propTypes.oneOfType([propTypes.bool]),
  onClick: propTypes.func,
  disabled: propTypes.bool,
}

Checkbox.defaultProps = {
  onChange: () => {},
  type: "double",
  controlled: false,
  checkValue: null,
  label: "",
  onClick: () => {},
  disabled: false,
}
