import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import "./input.scss"
import cn from "classnames"
import { get } from "lodash"

function Color({ type, name, label, required, className, onChange, ...props }) {
  const { register, watch, setValue, formState } = useFormContext()
  const value = watch(name)
  const { errors } = formState

  const handleChange = (e) => {
    onChange(e.target.value)
    if (type === "number") {
      setValue(name, /^\d*\.?\d*$/.test(e.target.value) ? e.target.value : value)
    } else {
      setValue(name, e.target.value)
    }
  }
  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="input">
        {label}
      </label>

      {/* {staticColors.map((color) => (
        <div className="">
          <span style={{ backgroundColor: color }}> </span>
        </div>
      ))} */}
      <input
        {...register(name)}
        type="color"
        className={`form-control ${className}`}
        autoComplete="off"
        maxLength="255"
        onChange={handleChange}
        name={name}
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Color

Color.propTypes = {
  type: propTypes.string,
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  className: propTypes.string,
  onChange: propTypes.func,
}

Color.defaultProps = {
  type: "text",
  label: "",
  className: "",
  required: false,
  onChange: () => {},
}
