const set = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data))
}

const get = (name) => {
  let res
  const item = localStorage.getItem(name)

  try {
    res = JSON.parse(item)
  } catch (e) {
    res = {}
  }

  return res
}

const remove = (name) => localStorage.removeItem(name)

const storage = { set, remove, get }

export default storage
