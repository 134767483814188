function GiveMoneyToProvider() {
  return (
    <div className="modal__provider">
      {/* <Form>
        {() => (
          <>
            <Fields />
          </>
        )}
      </Form> */}
    </div>
  )
}

export default GiveMoneyToProvider
