import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import cn from "classnames"

import "./rating.scss"
import { Tooltip } from "react-tippy"
import { get } from "lodash"

function Rating({ name, required, label, ratings, onClick = () => {} }) {
  const { register, setValue, getValues, watch, formState } = useFormContext()
  register(name)
  watch(name)

  const { errors } = formState

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <ul className="form__rating" key={JSON.stringify(ratings)}>
        {ratings.map(({ label: itemLabel, value, description }) => (
          <Tooltip
            key={value}
            title={description || ""}
            hideDelay={0}
            position="top"
            arrow
            trigger="mouseenter"
            animation="none"
            duration={0}
            disabled={!description}
            className="tooltip"
          >
            <li
              onClick={() => {
                setValue(name, value)
                onClick(value)
              }}
              className={getValues()[name] === value ? "active" : ""}
            >
              {itemLabel}
            </li>
          </Tooltip>
        ))}
      </ul>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Rating

Rating.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  ratings: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]),
      label: propTypes.oneOfType([propTypes.string, propTypes.number]),
    }),
  ),
}

Rating.defaultProps = {
  label: "",
  required: false,
  ratings: [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ],
}
