import { useState } from "react"
import propTypes from "prop-types"
import qs from "qs"

import "./styles.scss"
import { Button } from "@mantine/core"
import { Icons } from "components"
import { useSearchParams } from "react-router-dom"

function Search({ type, name = "query", label, required, className, onChange, ...props }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get(name) || "")
  const inputType = type === "number" ? "text" : type

  const search = () => {
    const newParams = {
      ...qs.parse(searchParams.toString()),
      page: 1,
    }

    if (value !== 1) newParams[name] = value

    setSearchParams(newParams)
  }

  return (
    <div className="form-group">
      <div className="search-wrap">
        <Button
          variant="default"
          className="icon"
          onClick={() => {
            searchParams.delete("page")
            searchParams.delete("search")
            setSearchParams(searchParams, { replace: true })
            setValue("")
          }}
        >
          <Icons.XIcon />
        </Button>
        <input
          type={inputType}
          className={`form-control ${className}`}
          autoComplete="off"
          maxLength="255"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name={name}
          onKeyUp={(e) => {
            if (e.key === "Enter") search()
          }}
          {...props}
        />
        <Button type="submit" className="submit" onClick={search}>
          Izlash
        </Button>
      </div>
    </div>
  )
}

export default Search

Search.propTypes = {
  type: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  required: propTypes.bool,
  className: propTypes.string,
  onChange: propTypes.func,
}

Search.defaultProps = {
  type: "text",
  label: "",
  className: "",
  name: "query",
  required: false,
  onChange: () => {},
}
