import { Navigate, createBrowserRouter } from "react-router-dom"
import ErrorBoundary from "components/ErrorBoundary"
import Pages from "./Pages"

const routes = [
  {
    path: "/signin",
    index: true,
    element: <Pages.Signin2 />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "*",
    element: <Navigate to="/signin" replace />,
    errorElement: <ErrorBoundary />,
  },
]

const GuestRoutes = createBrowserRouter(routes)

export default GuestRoutes
