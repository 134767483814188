import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"
import cn from "classnames"
import { get } from "lodash"

import "./editor.scss"

function Editor({ name, label, required }) {
  const { register, setValue, watch, getValues, formState } = useFormContext()
  watch(name)
  register(name)
  const { errors } = formState

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={getValues()[name]}
        onChange={(event, editor) => {
          const data = editor.getData()
          setValue(name, data)
        }}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Editor

Editor.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
}

Editor.defaultProps = {
  label: "",
  required: false,
}
