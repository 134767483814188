/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { get } from "lodash"
import { useEffect } from "react"
import { useRouteError } from "react-router-dom"
import * as Sentry from "@sentry/react"

import "./styles.scss"

function ErrorBoundary() {
  const error = useRouteError()

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])

  return (
    <div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Sahifada Xatolik.</h3>
            <h1>
              <span>5</span>
              <span>0</span>
              <span>0</span>
            </h1>
          </div>
          <h2>{error.message}</h2>
          <p className="c-red">{(get(error, "stack", "") || "").slice(0, 400)}</p>
          <br />
          <button
            type="button"
            className="notfound-goback"
            onClick={() => window.location.reload(true)}
          >
            Sahifani yangilash
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorBoundary
