import { Suspense, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Spinner } from "components"

import Navbar from "./Navbar"

import "./layout.scss"

function Layout() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="layout">
      <Navbar />
      <div className="page-wrapper">
        {/* <Sidebar /> */}
        <main className="page-content">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  )
}

export default Layout
