/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
import { get } from "lodash"
import pt from "prop-types"
import { useFormContext } from "react-hook-form"
import Checkbox from "../Checkbox"

import "./permissions.scss"

const obj = {}

function Permissions({ permissions }) {
  const { getValues, setValue } = useFormContext()
  const values = get(getValues(), "permissions")

  const getChecked = (items) => {
    const filtered = items.filter(({ permission }) => get(values, permission))
    if (filtered.length === 0) {
      return null
    }
    if (filtered.length > 0 && filtered.length < items.length) {
      return false
    }
    if (filtered.length === items.length) {
      return true
    }
    return null
  }

  return (
    <div className="permission mb-20 p-20">
      {Array.isArray(permissions) &&
        permissions.map(({ tab, items }, i) => (
          <div className={`permission__route ${i > 0 ? "bt" : ""} pt-10`} key={tab}>
            <Checkbox
              type="triple"
              name={tab}
              label={tab}
              onClick={() => {
                const hasTrue = items.filter(({ permission }) => values[permission])
                items.forEach(({ permission }) => {
                  setValue(`permissions.${permission}`, !hasTrue.length)
                })
              }}
              checkValue={getChecked(items)}
              controlled
            />
            <div className="">
              <ul>
                {items.map(({ label, permission }) => (
                  <li key={permission}>
                    <span>└─</span>{" "}
                    <Checkbox
                      name={`permissions.${permission}`}
                      label={label}
                      onChange={(value) => {
                        obj[tab] = {
                          ...obj[tab],
                          [permission]: !value,
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Permissions

Permissions.propTypes = {
  permissions: pt.object,
}

Permissions.defaultProps = {
  permissions: {},
}
