/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"
import { Form } from "containers"
import { Fields, Icons } from "components"

import "./select-tooltip.scss"

const data = [
  {
    title: "laptop",
  },
  {
    title: "airpods",
  },
  {
    title: "auto",
  },
]

function SelectTooltip({ tooltipType, name, label, execute }) {
  const { register, setValue, getValues } = useFormContext()

  const [list, setList] = useState({
    unselected: data,
    selected: {},
  })

  const [active, setActive] = useState()
  register(name)

  const select = (item, index) => {
    const { selected } = list
    setList({
      unselected: list.unselected.filter((_, i) => index !== i),
      selected,
    })
    setValue(name, selected)
  }

  const clear = () => {
    setValue(name, null)
  }

  useEffect(() => {
    if (!getValues()[name]) {
      clear()
    }
  }, [getValues()[name]])

  return (
    <div className={`select-tooltip__wrapper ${active ? "active" : ""}`}>
      <div className="select-tooltip__overlay" onClick={() => setActive(false)}>
        {" "}
      </div>
      <div
        className="select-tooltip"
        onClick={(e) => {
          setActive(true)
          e.stopPropagation()
        }}
      >
        <div className="select-tooltip__header">
          <div className="select-tooltip__label">{label}</div>
          {list.selected.length ? (
            <span className="select-tooltip__count">{list.selected.length}</span>
          ) : null}
          <Icons.AngleDown width="10px" height="auto" />
        </div>
        <div className="select-tooltip__tooltip" onClick={(e) => e.stopPropagation()}>
          <div>
            {tooltipType === "select" ? (
              <>
                <div className="select-tooltip__tooltip__header">
                  <Form>
                    {() => <Fields.Input name={name} className="select-tooltip__search-input" />}
                  </Form>
                  <Icons.Search className="select-tooltip__search-icon" />
                </div>
                <div className="select-tooltip__tooltip-list">
                  <ul>
                    {list.unselected.map((item, i) => (
                      <li key={i} onClick={() => select(item, i)}>
                        <Icons.Plus />
                        <p>{item.title}</p>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {/* {list.selected.map((item, i) => (
                      <li key={i} onClick={() => unselect(item, i)}>
                        <Icons.Minus />
                        <p>{item.title}</p>
                      </li>
                    ))} */}
                  </ul>
                </div>
              </>
            ) : null}
            {tooltipType === "date" ? (
              <Fields.Datepicker name="date" label="равно" onChange={execute} isClearable />
            ) : null}
          </div>
          <div className="btn-group">
            <button type="button" className="btn btn-primary" onClick={() => execute()}>
              Применить
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setValue(name, null)
                execute()
              }}
            >
              Очистить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectTooltip

SelectTooltip.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  tooltipType: propTypes.string,
  execute: propTypes.func,
}

SelectTooltip.defaultProps = {
  label: "",
  tooltipType: "select",
  execute: () => {},
}
