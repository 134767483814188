/* eslint-disable no-nonoctal-decimal-escape */
/* eslint-disable react/prop-types */
import { useFormContext } from "react-hook-form"

import "./input.scss"
import cn from "classnames"
import { get } from "lodash"
import ReactInputMask from "react-input-mask"

function InputMask({
  type,
  name,
  label,
  required,
  className,
  onChange = () => {},
  extraBtn,
  canMinus,
  ...props
}) {
  const { watch, getValues, setValue, formState } = useFormContext()
  watch(name)
  const { errors } = formState
  const inputType = type === "number" ? "text" : type

  const handleChange = (e) => {
    onChange(e.target.value)
    if (type === "number") {
      setValue(
        name,
        e.target.value === "" ||
          (canMinus && e.target.value === "-") ||
          Number.isNaN(e.target.value * -1) === false
          ? e.target.value
          : getValues(name),
      )
    } else {
      setValue(name, e.target.value)
    }
  }

  return (
    <div className="form-group">
      {label ? (
        <label className={`form-label ${required ? "required" : ""}`} htmlFor="input">
          {label}
        </label>
      ) : null}

      <div className={`form-group__input-wrap ${extraBtn ? "--has-extra" : ""}`}>
        <ReactInputMask
          value={getValues(name)}
          type={inputType}
          className={`form-control ${className}`}
          autoComplete="off"
          alwaysShowMask
          maskChar=""
          onChange={handleChange}
          name={name}
          {...props}
        >
          {(inputProps) => <input {...inputProps} />}
        </ReactInputMask>

        <div className="extra-btn">{extraBtn}</div>
      </div>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default InputMask
