import { Delete, Fields } from "components"
import { Form } from "containers"

function Stream() {
  return (
    <div className="stream-card">
      <div className="stream-card__header space-b">
        <h2 className="sk" style={{ maxWidth: "100px" }}>
          {" "}
        </h2>
        <div className="icon">
          <Delete url="streams" itemId={0} />
        </div>
      </div>
      <div className="stream-card__body">
        <Form>
          {() => (
            <>
              <Fields.Input
                name="link"
                label={
                  <p className="sk" style={{ minWidth: "50px" }}>
                    {" "}
                  </p>
                }
                disabled
                className="sk"
              />
              <Fields.Checkbox
                name="is_region_disabled"
                label={
                  <span className="sk" style={{ minWidth: "100px", marginTop: "3px" }}>
                    {" "}
                  </span>
                }
              />
              <Fields.Checkbox
                name="cherity"
                label={
                  <span className="sk" style={{ minWidth: "100px", marginTop: "3px" }}>
                    {" "}
                  </span>
                }
                disabled
              />
            </>
          )}
        </Form>
      </div>
      <div className="stream-card__footer bt pt-20">
        <button type="button" className="btn btn-primary">
          Nusxa ko&apos;chirish
        </button>
      </div>
    </div>
  )
}

export default Stream
