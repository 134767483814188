import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"

import "./switch.scss"
import cn from "classnames"

function Switch({ name, label }) {
  const { register, setValue, getValues, watch } = useFormContext()
  watch(name)
  register(name)

  const toggleCheck = () => {
    setValue(name, Number(!getValues(name)))
  }

  return (
    <div className="form-group  switch-field">
      <div className="form-check form-switch" onClick={toggleCheck}>
        <div className={cn("form-switch__input", { checked: getValues(name) })}> </div>
        <label className="form-check-label" htmlFor="Form-field-Product-is_public">
          {label}
        </label>
      </div>
    </div>
  )
}

export default Switch

Switch.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
}
