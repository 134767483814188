/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react"
import propTypes from "prop-types"
import { get } from "lodash"
import cn from "classnames"

import { useRequest } from "hooks"
import "./select-modal.scss"
import { useFormContext } from "react-hook-form"
import { Icons } from "components"
import Modal from "components/Modal"
import SelectModalList from "./SelectModalList"

function SelectModal({ name, label, required, modalTitle, url, columns, select, disabled }) {
  const [isOpen, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState()
  const { register, setValue, getValues, formState } = useFormContext()
  register(name)

  const [selected, setSelected] = useState(getValues()[name])
  const { errors } = formState

  const urlSearchParams = {
    page,
  }
  if (search) urlSearchParams.search = search
  const { data, isLoading } = useRequest({
    url,
    urlSearchParams,
  })
  const value = getValues()[name]
  const user = useRequest({
    url: `${url}/${value}`,
    urlSearchParams,
    enabled: typeof value === "number" || typeof value === "string",
  })

  useEffect(() => {
    if (selected) {
      setValue(name, selected)
    }
  }, [selected])

  useEffect(() => {
    if (user.data) setSelected(get(user.data, "data"))
  }, [user.isLoading])

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <div className={`form-select ${disabled ? "disabled" : ""}`}>
        <div className="form-select__header">
          {selected ? (
            <div className="form-select__selected">
              <div className="form-select__value">
                {select.map((txt, i) => (
                  <span key={txt}>
                    {get(selected, txt)} {select.length - 1 > i ? "-" : ""}{" "}
                  </span>
                ))}
              </div>
              {disabled ? null : (
                <div className="form-select__actions">
                  <button type="button" className="btn" onClick={() => setOpen(true)}>
                    <Icons.Edit />
                  </button>
                  <button className="btn" type="button" onClick={() => setSelected()}>
                    <Icons.XIcon className="x-icon" />
                  </button>
                </div>
              )}
            </div>
          ) : disabled ? null : (
            <button type="button" className="form-select__btn" onClick={() => setOpen(true)}>
              <Icons.FileCheck />
              <p className="form-select__btn-text">Select</p>
            </button>
          )}
        </div>
      </div>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
      <Modal isOpen={isOpen} handleClose={() => setOpen(false)} title={modalTitle}>
        <SelectModalList
          data={get(data, "data")}
          setSelected={setSelected}
          setOpen={setOpen}
          columns={columns}
          setPage={setPage}
          page={page}
          pageCount={get(data, "meta.last_page", 0)}
          onSearch={(obj) => setSearch(get(obj, "search"))}
          isLoading={isLoading}
        />
        <div className="select-modal__bottom">
          <button type="button" className="btn btn-default" onClick={() => setOpen(false)}>
            Cencel
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default SelectModal

SelectModal.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  modalTitle: propTypes.string,
  url: propTypes.string,
  columns: propTypes.array,
  select: propTypes.array,
  disabled: propTypes.bool,
}

SelectModal.defaultProps = {
  label: "",
  required: false,
  modalTitle: "",
  url: "",
  columns: [],
  select: [],
  disabled: false,
}
