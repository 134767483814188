/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */

import { useFormContext } from "react-hook-form"
import propTypes from "prop-types"

import "./radios.scss"
import { get } from "lodash"

function Radios({
  name,
  label,
  required,
  options,
  onClick,
  optionValue,
  optionLabel,
  disabled = () => {},
}) {
  const { register, watch, setValue, getValues } = useFormContext()

  register(name)
  watch(name)

  const inputValue = getValues(name)

  return (
    <div>
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <ul className="form-radio-btns">
        {options.map((data, i) => (
          <li
            key={get(data, optionValue)}
            onClick={() => {
              onClick(data)
              if (!disabled(data, i)) {
                setValue(name, data)
              }
            }}
            className={`form__radio-btn 
            ${get(data, optionValue) === get(inputValue, "id") ? "active" : ""}
            ${disabled(data, i) ? "disabled" : ""}
            `}
          >
            <div>
              {typeof optionLabel === "function" ? optionLabel(data) : get(data, optionLabel)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Radios

Radios.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.any,
      label: propTypes.string,
    }),
  ),
  onClick: propTypes.func,
  optionValue: propTypes.string,
  optionLabel: propTypes.string,
}

Radios.defaultProps = {
  label: "",
  required: false,
  options: [],
  onClick: () => {},
  optionValue: "value",
  optionLabel: "label",
}
