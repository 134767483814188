import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import cn from "classnames"
import { get } from "lodash"

import "./textarea.scss"

function Textarea({ label, required, name, ...props }) {
  const { register, formState, watch } = useFormContext()
  watch(name)

  const { errors } = formState

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <textarea
        {...register(name)}
        name={name}
        cols="30"
        rows="7"
        className="form__textarea"
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Textarea

Textarea.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
}

Textarea.defaultProps = {
  label: "",
  required: false,
}
