/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

import ReactSelect from "react-select"
import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import cn from "classnames"

import "./select.scss"
import { get } from "lodash"

function Select({ name, label, required, options, defaultValue, ...props }) {
  const { register, setValue, watch, getValues, formState } = useFormContext()
  watch(name)
  const { errors } = formState

  register(name)

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <ReactSelect
        key={JSON.stringify(getValues()[name])}
        className="select"
        classNamePrefix="select"
        onChange={(option) => setValue(name, option)}
        value={getValues(name)}
        defaultValue={getValues(name)}
        options={options}
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Select

Select.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  options: propTypes.array,
  defaultValue: propTypes.shape({
    value: propTypes.any,
    label: propTypes.string,
  }),
}

Select.defaultProps = {
  label: "",
  required: false,
  options: [],
  defaultValue: {},
}
