/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react"
import propTypes from "prop-types"

import "./upload.scss"
import { useFormContext } from "react-hook-form"
import { Icons } from "components"
import { get } from "lodash"
import cn from "classnames"

import { helpers } from "services"

function Upload({
  name,
  label,
  required,
  isMulti,
  disabled,
  placeholder = "PDF, PNG, JPEG va DOC Falyni tashlang yoki fayl yuklashni bosing",
  accept = "*",
}) {
  const { register, setValue, getValues, formState } = useFormContext()
  register(name)
  const { errors } = formState

  const initalValue = get(getValues(), name, [])
  const [files, setFiles] = useState(initalValue)

  const upload = (e) => {
    if (isMulti) {
      setFiles([...files, ...e.target.files])
    } else {
      setFiles([...e.target.files])
    }
  }

  const getName = (file) => {
    if (typeof file === "string") return file
    return get(file, "name")
  }

  const removeItem = (i) => {
    setFiles(files.filter((file, j) => i !== j))
  }

  useEffect(() => {
    setValue(name, files)
  }, [files])

  const getFileType = (file) => {
    if (file.type.includes("image")) return "image"
    if (file.type.includes("video")) return "video"
    return "file"
  }

  return (
    <div className="ticket-form-group">
      <label className={`ticket-form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <div className="ticket-form-upload">
        {files.length ? (
          <div className="ticket-form-upload__list">
            {files.map((file, i) => (
              <div className="ticket-form-upload__item" key={i}>
                <div className="ticket-form-upload__item-img">
                  {getFileType(file) === "image" ? <Icons.ImageIcon /> : null}
                  {getFileType(file) === "video" ? <Icons.Video /> : null}
                  {getFileType(file) === "file" ? <Icons.File /> : null}
                </div>
                <div className="center">
                  <div>
                    <p className="file__title">{getName(file)}</p>
                    <p className="file__subtitle">
                      Hajmi: <span className="file__size">{helpers.formatBytes(file.size)}</span>
                    </p>
                  </div>
                </div>
                <div className="right">
                  <div className="ticket-form-upload__item-delete" onClick={() => removeItem(i)}>
                    <Icons.XIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {files.length < 1 ? (
          <p className="placeholder">{placeholder}</p>
        ) : (
          isMulti && (
            <button type="button" className="ticket-form-upload__btn">
              <Icons.Plus />
              <p className="ticket-form-upload__btn-text">Yana yuklash</p>
              {/* <input key={files.length} type="file" onChange={upload} multiple={isMulti} /> */}
            </button>
          )
        )}
        <input
          className="bg-input"
          key={files.length}
          type="file"
          onChange={upload}
          multiple={isMulti}
          disabled={disabled}
          accept={accept}
        />
      </div>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Upload

Upload.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  isMulti: propTypes.bool,
}

Upload.defaultProps = {
  label: "",
  required: false,
  isMulti: false,
}
